// Import components
import { ref } from 'vue'
import { defineStore } from 'pinia'

// Import Sentry
import * as Sentry from '@sentry/vue'

export const usePdokDataStore = defineStore('pdokDataStore', () => {
  const pdokData = ref<any>(null)
  const pdokPostcodeValid = ref<Boolean | undefined>(undefined)
  const pdokHouseNumberValid = ref<Boolean | undefined>(undefined) // House number can be invalid, but user can proceed

  async function fetchPdokData(postcode: string, housenumber: string | null = null) {
    if (!postcode) {
      throw new Error('Invalid postcode')
    }

    // Remove spaces from the input
    const cleanedPostcode = postcode.replace(/\s+/g, '')
    const cleanedHouseNumber = housenumber ? housenumber.replace(/\s+/g, '') : null

    try {
      // Make the initial fetch request (with or without house number)
      let response = await fetch(
        `https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?fq=postcode:${cleanedPostcode}` +
          (cleanedHouseNumber ? `&fq=huisnummer:${cleanedHouseNumber}` : '') +
          `&rows=1`
      )

      if (!response.ok) {
        Sentry.captureMessage(`Pdok API request failed with status: ${response.status}`)
        pdokPostcodeValid.value = false
        pdokHouseNumberValid.value = false
        return
      }

      let data = await response.json()

      // Scenario 1 or 3: Valid postcode, valid or undetermined house number
      if (data.response.numFound >= 1) {
        pdokData.value = data.response.docs[0]
        pdokPostcodeValid.value = true
        pdokHouseNumberValid.value = cleanedHouseNumber ? true : undefined
        return
      }

      // Scenario 2: Retry with only the postcode if the house number was invalid
      if (cleanedHouseNumber) {
        response = await fetch(
          `https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?fq=postcode:${cleanedPostcode}&rows=1`
        )

        if (!response.ok) {
          Sentry.captureMessage(`Pdok API request failed with status: ${response.status}`)
          throw new Error(`API request failed with status: ${response.status}`)
        }

        data = await response.json()

        if (data.response.numFound >= 1) {
          pdokData.value = data.response.docs[0] // Ensure we are assigning the docs correctly
          pdokPostcodeValid.value = true
          pdokHouseNumberValid.value = false // Postcode valid, house number invalid
          return
        }

        pdokPostcodeValid.value = false
        pdokHouseNumberValid.value = false
        return
      }

      pdokPostcodeValid.value = false
    } catch (error) {
      Sentry.captureException(error)
      pdokPostcodeValid.value = false
      pdokHouseNumberValid.value = false
    }
  }

  return {
    pdokData,
    pdokPostcodeValid,
    pdokHouseNumberValid,
    fetchPdokData
  }
})
