/**
 * Cleans and consolidates form data by merging properties that start with 'formstep_'.
 * This is used to combine step-specific data into a single object before submitting.
 *
 * @param data - The original form data object, expected to have a 'formdata' property.
 * @returns An object with cleaned and consolidated form data.
 */
export function cleanFormData(data: { formdata: Record<string, any> }): Record<string, any> {
  const formData = JSON.parse(JSON.stringify(data.formdata))

  Object.keys(data.formdata).forEach((key) => {
    if (key.startsWith('formstep_')) {
      Object.assign(formData, data.formdata[key])
      delete formData[key]
    }
  })

  // Combine 'house_number' and 'house_number_addition' if both exist
  if (formData.address.house_number && formData.address.house_number_addition) {
    formData.address.house_number = `${formData.address.house_number} ${formData.address.house_number_addition.trim()}`
    delete formData.address.house_number_addition
  }

  // Convert 'width' to cm for the backend and null if 0
  if (formData.width) {
    const normalizedWidth =
      typeof formData.width === 'string' ? formData.width.replace(',', '.') : formData.width
    const widthInCm = parseFloat(normalizedWidth) * 100
    formData.width = isNaN(widthInCm) ? formData.width : widthInCm

    if (formData.width === 0) {
      formData.width = null
    }
  }

  return formData
}
