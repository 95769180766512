import { defineStore } from 'pinia'

type Config = {
  dev: boolean
  formMode: string
  isCrossSell: boolean
  partnerId: string
  productId: string
  redirectUrl: string
  standAloneProps: {
    pageTitle: string
    productSingular: string
    productPlural: string
  }
  useQueryParams: boolean
  formSubmitEndpoint: string | null
}

export const useFormConfigStore = defineStore('formConfigStore', {
  state: () =>
    ({
      dev: false,
      formMode: '',
      productId: '',
      redirectUrl: '',
      standAloneProps: {
        pageTitle: '',
        productSingular: '',
        productPlural: ''
      },
      useQueryParams: true,
      formSubmitEndpoint: null
    }) as Config,
  getters: {
    isStandalone: (state) => state.formMode === 'standAlone'
  },
  actions: {
    setConfig(config: Config) {
      Object.assign(this, config)
    }
  }
})
