<template>
  <div class="contact-details">
    <FormKit
      help="Hierop ontvang je de offertes."
      id="email"
      label="E-mail"
      name="email"
      outer-class="contact-details__email"
      placeholder="naam@provider.nl"
      type="email"
      validation="required|email"
    >
      <template #prefixIcon><span class="formkit-prefixIcon"></span></template>
    </FormKit>
    <FormKit
      :validation="[
        ['required'],
        [
          'matches',
          /^((\+|00(\s|\s?\-\s?)?)(31|32)(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/
        ]
      ]"
      help="Voor eventuele vragen van vakbedrijven."
      id="phone_number"
      label="Telefoon / Whatsapp"
      name="phone_number"
      outer-class="contact-details__phonenumber"
      placeholder="0612345678"
      type="tel"
    >
      <template #prefixIcon><span class="formkit-prefixIcon"></span></template>
    </FormKit>
    <FormKit
      :options="{ on: 'Ja, ik wil de Slimster-nieuwsbrief ontvangen' }"
      id="opt_in"
      label="Nieuwsbrief"
      name="opt_in"
      outer-class="contact-details__opt-in"
      type="checkbox"
    />
  </div>
</template>
