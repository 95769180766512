// Import Sentry
import * as Sentry from '@sentry/vue'

// Import stores
import { useLoadingStore } from '@/stores/loaderIndicatorStore'

/**
 * Loads a form schema from the CDN based on the specified form type.
 * Fetches the schema JSON and sets it in the provided ref variable.
 *
 * @param productFormSchema - The product related form schema we need to load
 * @param schemaRef - The Vue ref to store the loaded schema object.
 * @returns Promise<void> - Resolves when the schema is successfully loaded or set to null on failure.
 */
export async function loadFormSchema(
  productFormSchema: string,
  schemaRef: { value: any }
): Promise<void> {
  const loadingStore = useLoadingStore()
  loadingStore.startLoading('Formulier wordt geladen')

  try {
    const response = await fetch(
      `${import.meta.env.VITE_CDN_URL}/form-schemas/${productFormSchema}.json`
    )

    if (!response.ok) {
      schemaRef.value = null
      throw new Error(`Failed to load the ${productFormSchema} schema`)
    }

    schemaRef.value = await response.json()
  } catch (error) {
    schemaRef.value = null
    Sentry.captureException(error)
    throw error
  } finally {
    loadingStore.stopLoading()
  }
}
