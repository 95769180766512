// Import Sentry
import * as Sentry from '@sentry/vue'

// Import stores
import { useFormConfigStore } from '@/stores/formConfigStore'
import { useLoadingStore } from '@/stores/loaderIndicatorStore'

/**
 * Submits cleaned form data to the Slimster API.
 * Sends the data as a POST request and redirects to a thank-you URL if provided.
 *
 * @param formData - The form data object to be submitted after cleaning.
 * @param redirectUrl - The URL to redirect upon successful submission, optionally appending an ID if returned from the API.
 * @returns Promise<void> - Resolves when the submission is complete.
 */
export async function submitFormData(formData: any, redirectUrl?: string): Promise<void> {
  const formConfigStore = useFormConfigStore()
  const loadingStore = useLoadingStore()
  loadingStore.startLoading('De aanvraag wordt verstuurd...')

  try {
    const apiUrl = formConfigStore.formSubmitEndpoint || import.meta.env.VITE_API_URL

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(formData)
    })

    if (response.ok) {
      const data = await response.json()

      // Fire submitted event
      if (typeof response === 'object' && response !== null) {
        window.dispatchEvent(
          new CustomEvent('slmstrFormSubmitted', {
            detail: {
              formType: formConfigStore.productId,
              formResponseData: data
            }
          })
        )
      }

      // Redirect the user after a succesfull form submission
      if (redirectUrl) {
        // If we got a lead ID attach it to the redirect URL
        if (data.id) {
          window.location.href = `${redirectUrl}?l=${data.id}`
          return
        }

        window.location.href = redirectUrl
      }

      return
    }

    /**
     * In case of errors lets pass them through so we can
     * inform the user where needed.
     */
    const errorData = await response.json()
    throw new Error(JSON.stringify(errorData))
  } catch (error) {
    loadingStore.stopLoading()
    Sentry.captureException(error)
    throw error
  }
}
