<script setup lang="ts">
// Import components
import { ref } from 'vue'

const gender = ref<any>('m') // Defaults to `man`
</script>

<template>
  <div class="contact-details">
    <FormKit
      :options="{ m: 'Dhr.', f: 'Mevr' }"
      :config="{
        classes: {
          options: 'inline'
        }
      }"
      id="gender"
      label="Aanhef"
      name="gender"
      outer-class="contact-details__gender"
      type="radio"
      validation="required"
      v-model="gender"
    />
    <div class="contact-details__name-wrapper">
      <div class="contact-details__name-label formkit-label">Naam</div>
      <FormKit
        id="first_name"
        label="Voornaam"
        name="given_name"
        outer-class="contact-details__given-name forced-vertical"
        type="text"
        validation="required"
      />
      <FormKit
        id="last_name"
        label="Achternaam"
        name="family_name"
        outer-class="contact-details__family-name forced-vertical"
        type="text"
        validation="required"
      />
    </div>
  </div>
</template>
