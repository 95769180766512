<script setup lang="ts">
// Import components
import { storeToRefs } from 'pinia'

// Import stores
import { useLoadingStore } from '@/stores/loaderIndicatorStore'

const loadingStore = useLoadingStore()
const { isLoading, message } = storeToRefs(loadingStore)
</script>

<template>
  <div
    v-if="isLoading"
    class="slmstrfrms__app-loading"
  >
    <div class="loader"></div>
    <div class="message">{{ message }}</div>
  </div>
</template>
