const svgCache = new Map()

export async function loadSvg(name: string): Promise<string> {
  if (svgCache.has(name)) {
    return svgCache.get(name)
  }

  try {
    const response = await fetch(`${import.meta.env.VITE_CDN_URL}/icons/${name}.svg`)
    const svgText = await response.text()
    svgCache.set(name, svgText)
    return svgText
  } catch (error) {
    console.error(`Failed to load SVG: ${name}`, error)
    return ''
  }
}
