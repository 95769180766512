<template>
  <div class="slmstrfrms-disclaimer">
    <div class="alert alert-success">
      <div class="alert__icon">
        <i class="frmkt-icon frmkt-icon-lock-regular"></i>
      </div>
      <div class="alert__message">
        Jouw gegevens worden veilig opgeslagen en eenmalig naar de beste 4 bedrijven gestuurd.
      </div>
    </div>
  </div>
</template>
