<script setup lang="ts">
// Import components
import { ref } from 'vue'

const gender = ref<any>('m') // Defaults to `man`
</script>

<template>
  <div class="contact-details">
    <FormKit
      :options="{ m: 'Dhr.', f: 'Mevr.' }"
      :config="{
        classes: {
          options: 'inline'
        }
      }"
      id="gender"
      label="Aanhef"
      name="gender"
      outer-class="contact-details__gender"
      type="radio"
      validation="required"
      v-model="gender"
    />
    <div class="contact-details__name-wrapper">
      <div class="contact-details__name-label formkit-label">Naam</div>
      <FormKit
        id="first_name"
        label="Voornaam"
        name="given_name"
        outer-class="contact-details__given-name forced-vertical"
        type="text"
        validation="required"
      />
      <FormKit
        id="last_name"
        label="Achternaam"
        name="family_name"
        outer-class="contact-details__family-name forced-vertical"
        type="text"
        validation="required"
      />
    </div>
    <FormKit
      help="Hierop ontvang je de offertes."
      id="email"
      label="E-mail"
      name="email"
      outer-class="contact-details__email"
      placeholder="naam@provider.nl"
      type="email"
      validation="required|email"
    >
      <template #prefixIcon><span class="formkit-prefixIcon"></span></template>
    </FormKit>
    <FormKit
      :validation="[
        ['required'],
        [
          'matches',
          /^((\+|00(\s|\s?\-\s?)?)(31|32)(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/
        ]
      ]"
      help="Voor eventuele vragen van vakbedrijven."
      id="phone_number"
      label="Telefoon / Whatsapp"
      name="phone_number"
      outer-class="contact-details__phonenumber"
      placeholder="0612345678"
      type="tel"
    >
      <template #prefixIcon><span class="formkit-prefixIcon"></span></template>
    </FormKit>
    <FormKit
      :options="{ on: 'Ja, ik wil de Slimster-nieuwsbrief ontvangen' }"
      id="opt_in"
      label="Nieuwsbrief"
      name="opt_in"
      outer-class="contact-details__opt-in"
      type="checkbox"
    />
  </div>
</template>
