// Default imports
import { defaultConfig } from '@formkit/vue'
import { nl } from '@formkit/i18n'
import { createLocalStoragePlugin } from '@formkit/addons'
import { createMultiStepPlugin } from '@formkit/addons'

// Import custom inputs
import { buttonCheckbox } from '@/inputs/buttonCheckbox'
import { buttonRadio } from '@/inputs/buttonRadio'

// Import custom plugins
import { addOptionalPlugin } from '@/utils/formkitAddOptionalPlugin'
import { scrollToErrorsPlugin } from '@/utils/formkitScrollToErrorsPlugin'

export default defaultConfig({
  config: {},
  inputs: {
    buttonCheckbox,
    buttonRadio
  },
  locales: { nl },
  locale: 'nl',
  messages: {
    nl: {
      ui: {
        next: 'Volgende',
        prev: 'Vorige',
        submit: 'Volgende'
      }
    }
  },
  plugins: [
    createLocalStoragePlugin({
      prefix: 'partial',
      key: undefined,
      control: undefined,
      maxAge: 3600000,
      debounce: 200,
      beforeSave: undefined,
      beforeLoad: undefined
    }),
    createMultiStepPlugin(),
    addOptionalPlugin,
    scrollToErrorsPlugin
  ],
  themes: null
})
