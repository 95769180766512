<script setup lang="ts">
import { ref } from 'vue'

const leadRequestTypeChoice = ref<any>('279') // Defaults to `particulier`
</script>

<template>
  <FormKit
    :options="[
      { label: 'Particulier', value: '279' },
      { label: 'Zakelijk', value: '280' }
    ]"
    id="lead_request_type_choice"
    label="Soort aanvraag"
    name="lead_request_type_choice"
    type="radio"
    v-model="leadRequestTypeChoice"
    validation="required"
  />

  <!-- These are only relevant for company request types -->
  <template v-if="leadRequestTypeChoice === '280'">
    <FormKit
      id="company_name"
      label="Bedrijfsnaam"
      name="company_name"
      type="text"
      validation="required"
    />

    <FormKit
      :options="[
        { label: 'Agrarisch', value: 662 },
        { label: 'Bedrijfspand', value: 428 },
        { label: 'Horeca', value: 429 },
        { label: 'Industrie', value: 430 },
        { label: 'Kantoorpand', value: 431 },
        { label: 'Kinderopvang', value: 930 },
        { label: 'Onderwijsinstelling', value: 432 },
        { label: 'Sportinstelling', value: 931 },
        { label: 'Winkel', value: 433 },
        { label: 'Zorginstelling', value: 434 },
        { label: 'Anders, zie omschrijving', value: 435 }
      ]"
      id="commercial_building_type"
      label="Soort pand"
      name="commercial_building_type"
      type="radio"
      validation="required"
    />
  </template>
</template>
