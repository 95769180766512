<script setup lang="ts">
// Import stores
import { useFormConfigStore } from '@/stores/formConfigStore'
import { computed } from 'vue'

const formConfigStore = useFormConfigStore()

const formProduct = computed(() => formConfigStore?.standAloneProps?.productSingular?.toLowerCase())
</script>

<template>
  <div class="slmstrfrms__app-header">
    <div class="slmstrfrms__app-header-top">
      <div class="container">
        <a
          href="//slimster.nl"
          target="_blank"
        >
          <img
            alt="slimster-logo"
            class="slmstrfrms__app-header-top-logo"
            src="/logo-white_2x.webp"
          />
        </a>
        <div class="slmstrfrms__app-header-top-score">
          <strong>94% beveelt ons aan</strong>
          <img
            alt="slimster-score"
            class="slmstrfrms__app-header-top-logo"
            src="/score.png"
          />
        </div>
      </div>
    </div>
    <div class="slmstrfrms__app-header-usp">
      <div class="container">
        <ul>
          <li>
            <i class="frmkt-icon frmkt-icon-circle-check-solid"></i>
            <strong>140+</strong>
            {{ formProduct }} specialisten
          </li>
          <li class="sm">
            <i class="frmkt-icon frmkt-icon-circle-check-solid"></i>
            <strong>Vrijblijvend</strong> 4 offertes
          </li>
          <li class="md">
            <i class="frmkt-icon frmkt-icon-circle-check-solid"></i>
            <strong>Vergelijk vrijblijvend</strong> 4 offertes
          </li>
          <li class="lg">
            <i class="frmkt-icon frmkt-icon-circle-check-solid"></i>
            <strong>Bespaar tijd & geld</strong>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/components/AppHeader';
</style>
