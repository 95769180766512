/**
 * Add optional to all fields that don't have the required attribute
 * @param node
 * @returns
 */
export function addOptionalPlugin(node: any) {
  const legends = ['checkbox_multi', 'radio_multi', 'repeater', 'transferlist']

  // Following types can be ignored
  if (['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(node.props.type)) {
    return
  }

  node.on('created', () => {
    const legendOrLabel = legends.includes(
      `${node.props.type}${node.props.options ? '_multi' : ''}`
    )
      ? 'legend'
      : 'label'

    if (node.props.definition.schemaMemoKey) {
      node.props.definition.schemaMemoKey += `${node.props.options ? '_multi' : ''}_add_optional`
    }

    const schemaFn = node.props.definition.schema
    node.props.definition.schema = (sectionsSchema: any = {}) => {
      sectionsSchema[legendOrLabel] = {
        children: [
          '$label',
          {
            $el: 'span',
            if: '$state.required === false',
            attrs: {
              class: 'formkit-optional'
            },
            children: ['(optioneel)']
          }
        ]
      }

      return schemaFn(sectionsSchema)
    }
  })
}
