<script setup lang="ts">
// Import components
import { computed } from 'vue'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppBody from '@/components/AppBody.vue'

// Import stores
import { useFormConfigStore } from '@/stores/formConfigStore'

const formConfigStore = useFormConfigStore()
</script>

<template>
  <AppHeader v-if="formConfigStore.isStandalone" />
  <AppBody :class="{ standalone: formConfigStore.isStandalone }" />
  <AppFooter v-if="formConfigStore.isStandalone" />
</template>
