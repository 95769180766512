<script setup lang="ts">
// Import components
import { onMounted, reactive, ref, watch } from 'vue'
import { getNode } from '@formkit/core'
import { FormKitMessages } from '@formkit/vue'

// Import stores
import { useFormConfigStore } from '@/stores/formConfigStore'
import { usePdokDataStore } from '@/stores/pdokDataStore'

// Types
interface Address {
  city: string
  country: 'NL' | 'BE'
  house_number: string
  house_number_addition: string
  postcode: string
  street: string
}

const formConfigStore = useFormConfigStore()
const pdokDataStore = usePdokDataStore()

const address = reactive<Address>({
  city: '',
  country: 'NL',
  house_number: '',
  house_number_addition: '',
  postcode: '',
  street: ''
})
const houseNumberInput = ref()

function storeAddressLocal() {
  localStorage.setItem('partial-address', JSON.stringify(address))
}

function getAddressLocal() {
  const storedAddress = localStorage.getItem('partial-address')

  if (storedAddress) {
    Object.assign(address, JSON.parse(storedAddress))
  }
}

function fetchPdokData(delay: number = 0) {
  setTimeout(() => {
    const sanitizedPostcode = address.postcode.replace(/\s+/g, '')

    if (/^[0-9]{4}[a-zA-Z]{2}$/.test(sanitizedPostcode)) {
      pdokDataStore.fetchPdokData(sanitizedPostcode, address.house_number || undefined)
    }
  }, delay)
}

function applyAddressQueryParams(queryParams: any) {
  queryParams.forEach((value: any, key: any) => {
    const normalizedKey = key.toLowerCase()

    switch (normalizedKey) {
      case 'housenr':
      case 'huisnr':
        getNode('house_number')?.input(value)
        break

      case 'postcode':
      case 'postalcode':
      case 'zip':
        getNode('postcode')?.input(value)
        break
    }
  })
}

/**
 * If we have query params and the config allows it, we apply the ones we know
 * to the form fields.
 */
onMounted(() => {
  getAddressLocal()

  if (window.location.search && formConfigStore.useQueryParams) {
    applyAddressQueryParams(new URLSearchParams(window.location.search))
  }
})

/**
 * Fill fields with pDok data when it's available
 */
watch(
  () => pdokDataStore.pdokData,
  () => {
    getNode('city')?.input(pdokDataStore.pdokData?.woonplaatsnaam)
    getNode('street')?.input(pdokDataStore.pdokData?.straatnaam)
  }
)
</script>

<template>
  <div class="address-lookup">
    <FormKit
      name="address"
      type="group"
    >
      <!-- Country selector, hidden for now -->
      <FormKit
        id="country"
        label="Land"
        name="country"
        outer-class="address-lookup__country"
        value="NL"
        type="hidden"
      />

      <div class="address-lookup__alt-label">Postcode / huisnummer</div>
      <div class="address-lookup__postcode">
        <!-- Postcode input -->
        <FormKit
          :inputmode="address.postcode === null || address.postcode.length < 4 ? 'numeric' : 'text'"
          :validation="[['required'], ['matches', /^[0-9]{4}\s?[a-zA-Z]{2}$/i]]"
          :validation-messages="{
            matches: 'Ongeldige postcode'
          }"
          @blur="storeAddressLocal"
          @input="fetchPdokData(250)"
          @keydown.space="(event: any) => event.preventDefault()"
          id="postcode"
          label="Postcode"
          maxlength="7"
          name="postal_code"
          pattern="[1-9][0-9]{3}\s?[a-zA-Z]{2}"
          placeholder="1234AB"
          type="text"
          v-model="address.postcode"
        />
      </div>

      <div class="address-lookup__house-number">
        <!-- Housenumber -->
        <FormKit
          @blur="storeAddressLocal"
          id="house_number"
          inputmode="numeric"
          label="Huisnummer"
          name="house_number"
          placeholder="12"
          ref="houseNumberInput"
          type="number"
          v-model="address.house_number"
          validation="required"
        />

        <!-- House number addition -->
        <FormKit
          @blur="storeAddressLocal"
          autocomplete="nope"
          id="house_number_addition"
          label="Toevoeging"
          name="house_number_addition"
          placeholder="Toevoeging"
          type="text"
          v-model="address.house_number_addition"
        />

        <FormKitMessages :node="houseNumberInput?.node" />
      </div>

      <div class="address-lookup__alt-label">Straat / plaats</div>

      <!-- Street -->
      <FormKit
        @blur="storeAddressLocal"
        id="street"
        label="Straat"
        name="street"
        outer-class="address-lookup__street"
        type="text"
        validation="required"
        v-model="address.street"
      />

      <!-- Municipality -->
      <FormKit
        @blur="storeAddressLocal"
        id="city"
        label="Plaats"
        name="city"
        outer-class="address-lookup__city"
        type="text"
        validation="required"
        v-model="address.city"
      />

      <div
        v-show="pdokDataStore.pdokPostcodeValid === false"
        class="address-lookup__unknown alert alert-warning"
      >
        Geen adres gevonden bij opgegeven postcode. Vul je adres in.
      </div>
    </FormKit>
  </div>
</template>
