/**
 * A little plugin that automatically scrolls to the first error.
 **/
export function scrollToErrorsPlugin(node: any) {
  const scrollTo = (child: any) => {
    const scrollToElement = document.getElementById(child.props.id)?.closest('.formkit-outer')
    if (scrollToElement) {
      scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const scrollToErrors = ({ payload }: any) => {
    if (payload.key !== 'submitted') {
      return
    }

    let parent = node
    while (parent && parent.props.type !== 'step') parent = parent.parent

    if (!parent) {
      return
    }

    parent.walk((child: any) => {
      if (child.ledger.value('blocking') || child.ledger.value('errors')) {
        scrollTo(child)
        return false
      }
    }, true)
  }

  node.on('message-added', scrollToErrors)
  node.on('message-updated', scrollToErrors)
}
