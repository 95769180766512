<script setup lang="ts">
// Import components
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { getNode } from '@formkit/core'
import { FormKitMessages } from '@formkit/vue'
import FormsDisclaimer from './FormsDisclaimer.vue'
import LoaderIndicator from './LoaderIndicator.vue'

// Import utils
import { cleanFormData } from '@/utils/cleanFormData'
import { loadFormSchema } from '@/utils/loadFormSchema'
import { submitFormData } from '@/utils/submitFormData'

// Import stores
import { useFormConfigStore } from '@/stores/formConfigStore'

const formConfigStore = useFormConfigStore()

const campaignData = ref<any>(null)
const formData = ref<any>(null)
const formNode = ref<any>(null)
const formFirstInteraction = ref<Boolean>(true)
const formSchema = ref<any>(null)
const multiStepNode = ref<any>(null)

/**
 * Checks if a value is selected in a list of choices.
 *
 * @param {string[]} choices - The list of choices to check against.
 * @param {string} value - The value to check for.
 *
 * @returns A boolean indicating if the value is selected.
 */
function hasSelected(choices: string[], value: string) {
  return Array.isArray(choices) && choices.includes(value)
}

/**
 * Navigates to the next step in the multi-step form.
 * This function is used as a callback in the FormKit schema and
 * should ONLY be used in the context of a multi-step form where
 * checkboxButton or radioButton is used to select a choice.
 *
 * @returns A function that navigates to the next step.
 */
function goNext() {
  return () => {
    const node = getNode('multiStep')
    const timeoutDuration = 100
    setTimeout(() => {
      node?.next()
    }, timeoutDuration)
  }
}

/**
 * Checks if the form is valid. As soon as the last mandatory field is
 * filled and becomes valid the form is ready to sent and considered valid
 * and the form class is updated to 'is-valid'.
 *
 * @returns A boolean indicating if the form is valid.
 */
const formIsValid = computed(() => formNode.value?.context?.state.valid)

/**
 * Extracts the list of step names from the multi-step form node.
 *
 * @returns An array of step names.
 */
const stepNames = computed(() => {
  return multiStepNode.value ? multiStepNode.value.children.map((child: any) => child.name) : []
})

/**
 * Retrieves the current active step from the multi-step form node.
 *
 * @returns The name of the active step, or undefined if not available.
 */
const activeStep = computed(() => multiStepNode.value?.context?.activeStep)

/**
 * Checks if the current active step is the last step.
 *
 * @returns A boolean indicating if the current step is the last one.
 */
const isLastStep = computed(() => {
  return activeStep.value === stepNames.value[stepNames.value.length - 1]
})

/**
 * Checks if the current active step is the first step.
 *
 * @returns A boolean indicating if the current step is the first one.
 */
const isFirstStep = computed(() => {
  return activeStep.value === stepNames.value[0]
})

/**
 * Handles the form submission event by cleaning the form data, submitting it.
 * @param {any} data - The raw form data that needs to be processed before submission.
 */
async function formSubmitHandler(data: any) {
  // 1. Cleans the form data using `cleanFormData`.
  const cleanedFormData = cleanFormData(data)

  // 2. Attach campaign data to the form data if available.
  if (
    campaignData.value &&
    typeof campaignData.value === 'object' &&
    Object.keys(campaignData.value).length > 0
  ) {
    cleanedFormData.campaign_data = campaignData.value
  }

  // 3. Submits the cleaned data with `submitFormData`, using the `redirectUrl` from the formConfigStore.
  try {
    await submitFormData(cleanedFormData, formConfigStore.redirectUrl)

    getNode(formConfigStore.productId)?.clearErrors()
  } catch (error: any) {
    try {
      const errorData = JSON.parse(error.message)
      const formErrors = [errorData.message]
      const fieldErrors = errorData.errors

      /**
       * Check if we have an error that contains "-" as a key,
       * if so use the generic error, else use the field error(s).
       */
      if (fieldErrors.hasOwnProperty('_')) {
        getNode(formConfigStore.productId)?.setErrors(formErrors)
      } else {
        for (const [field, message] of Object.entries(fieldErrors)) {
          const fieldNode = getNode(field)
          if (fieldNode) {
            fieldNode.setErrors([message as string])
          }
        }
      }
    } catch (parseError) {
      getNode(formConfigStore.productId)?.setErrors([
        'Er is iets mis gegaan met het verzenden van het formulier.'
      ])
      console.error('Error parsing backend error:', parseError)
    }
  }
}

/**
 * Dispatches a custom event when a form interaction occurs.
 * The event contains information about the form input, first interaction, form type, and form step.
 *
 * @param event
 */
function formInteractionHandler(event: any) {
  window.dispatchEvent(
    new CustomEvent('slmstrFormInteraction', {
      detail: {
        formInput: event.target.name,
        formFirstInteraction: formFirstInteraction.value,
        formType: formConfigStore.productId,
        formStep: activeStep.value
      }
    })
  )

  formFirstInteraction.value = false
}

/**
 * Focuses on the first empty input field in the visible step.
 *
 * @param stepId
 */
function formFocusHandler(stepId: any) {
  const visibleStep = document.getElementById(stepId)
  if (!visibleStep) {
    return
  }
}

/**
 * Filter out relevant campaign data and store it in a ref so we can send
 * it with the form data to the backend and monitor / track our campaigns.
 */
function getCamppaignData() {
  const campaignParams = ['adw_', 'gclid', 'msclkid', 'utm_', 'slm_']
  const queryParams = new URLSearchParams(window.location.search)

  campaignData.value = Object.fromEntries(
    [...queryParams].filter(
      ([key, value]) => campaignParams.some((param) => key.startsWith(param)) && value.trim() !== ''
    )
  )
}

onMounted(async () => {
  /**
   * Loads the correct schema and puts this in a reference so it
   * can be used to generate the FormKit form.
   */
  try {
    await loadFormSchema(formConfigStore.productId, formSchema)
    formNode.value = getNode(formConfigStore.productId)
    multiStepNode.value = getNode('multiStep')

    window.dispatchEvent(
      new CustomEvent('slmstrFormInitSuccess', {
        detail: {
          formType: formConfigStore.productId
        }
      })
    )
  } catch (error) {
    window.dispatchEvent(
      new CustomEvent('slmstrFormInitError', {
        detail: {
          formType: formConfigStore.productId
        }
      })
    )
  }

  if (window.location.search) {
    getCamppaignData()

    if (new URLSearchParams(window.location.search).get('dev') === 'true') {
      formConfigStore.dev = true
    }
  }
})

/**
 * Dispatches a custom event when the form step changes.
 */
watch(activeStep, (currentStep, previousStep) => {
  if (previousStep !== currentStep && previousStep !== undefined) {
    window.dispatchEvent(
      new CustomEvent('slmstrFormStepChanged', {
        detail: {
          formType: formConfigStore.productId,
          formStepPrev: previousStep,
          formStepCurrent: currentStep
        }
      })
    )
  }

  /**
   * In some cases we want to focus on an input directly,
   * we use nextTick here to make sure Vue is done with
   * updated to the DOM before we trigger it.
   */
  nextTick(() => {
    formFocusHandler(currentStep)
  })
})

watch(formIsValid, (newValue, oldValue) => {
  if (newValue === true && oldValue !== undefined) {
    window.dispatchEvent(
      new CustomEvent('slmstrFormBecameValid', {
        detail: {
          formType: formConfigStore.productId
        }
      })
    )
  }
})
</script>

<template>
  <div class="slmstrfrms__app-body">
    <div class="container">
      <!-- Page titles, only used in stand alone mode -->
      <template v-if="formConfigStore.isStandalone && formConfigStore?.standAloneProps?.pageTitle">
        <h1
          class="h4"
          v-html="formConfigStore.standAloneProps.pageTitle"
        ></h1>
      </template>

      <FormKit
        v-if="formSchema"
        :actions="false"
        :id="formConfigStore.productId"
        :name="formConfigStore.productId"
        @change="formInteractionHandler"
        @submit="formSubmitHandler"
        :form-class="formIsValid ? 'is-valid' : 'is-invalid'"
        use-local-storage
        type="form"
        v-model="formData"
      >
        <!-- Loaded form schema -->
        <FormKitSchema
          :data="{ hasSelected, goNext }"
          :schema="formSchema"
        />

        <!-- Global messages -->
        <FormKitMessages />

        <!-- Disclaimer to show on the last step -->
        <FormsDisclaimer v-if="activeStep === 'formstep_last'" />
      </FormKit>

      <div
        v-if="!formSchema"
        class="alert alert-warning"
      >
        <span class="monospace">`{{ formConfigStore.productId }}`</span> formulier kon niet geladen
        worden. Je kunt proberen de pagina te herladen.
      </div>

      <!-- Use queryparam dev=true to enable -->
      <pre
        v-if="formConfigStore.dev"
        v-html="JSON.stringify(formData, null, 2)"
      ></pre>
      <pre
        v-if="formConfigStore.dev"
        v-html="JSON.stringify(campaignData, null, 2)"
      ></pre>
    </div>

    <LoaderIndicator />
  </div>
</template>
