/**
 * Component and library imports
 */
import App from './App.vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'

// Import stores
import { useFormConfigStore } from '@/stores/formConfigStore'

// Import styles
import './assets/scss/app.scss'

// Formkit import
import { plugin, defaultConfig } from '@formkit/vue'
import formkitConfig from '@/formkit.config'

/**
 * Custom vue components that must be registered globally
 * so they can be used inside form schemas
 */
import AddressLookup from '@/components/AddressLookup.vue'
import ContactDetails from '@/components/ContactDetails.vue'
import ContactDetailsName from '@/components/ContactDetailsName.vue'
import ContactDetailsEmailPhone from '@/components/ContactDetailsEmailPhone.vue'
import RequestType from '@/components/RequestType.vue'

const app = createApp(App)

/**
 * Sentry
 */
Sentry.init({
  app,
  dsn: 'https://9de57e81ab3a60aedab888cc15450a96@o4505668214521856.ingest.us.sentry.io/4508444345696256',
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['vue-lead-forms'],
      behaviour: 'drop-error-if-contains-third-party-frames'
    })
  ]
})

/**
 * Create and use the Pinia store we use for
 * storing the config object.
 */
app.use(createPinia())

const formConfigStore = useFormConfigStore()

if (!(window as any).slimsterConfig) {
  Sentry.captureMessage('Configuration not provided in the embedded page.')
  console.error('Configuration not provided in the embedded page.')
}

formConfigStore.setConfig((window as any).slimsterConfig)

/**
 * Load the FormKit config and custom components.
 *
 * Components need to be registered here so they are available
 * for the schemas we build are FormKit forms with.
 */
app.use(plugin, defaultConfig(formkitConfig))
app.component('AddressLookup', AddressLookup)
app.component('ContactDetails', ContactDetails)
app.component('ContactDetailsName', ContactDetailsName)
app.component('ContactDetailsEmailPhone', ContactDetailsEmailPhone)
app.component('RequestType', RequestType)

app.mount('#slmstrfrms')
