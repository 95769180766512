<script setup lang="ts"></script>

<template>
  <div class="slmstrfrms__app-footer">
    <div class="slmstrfrms__app-footer-top">
      <div class="container">
        <div class="slmstrfrms__app-footer-top-twlogo">
          <img
            alt="thuiswinkel-waarborg-logo"
            loading="lazy"
            src="/thuiswinkel-waarborg-logo.webp"
          />
        </div>
        <ul class="slmstrfrms__app-footer-top-proof">
          <li>
            <i class="frmkt-icon frmkt-icon-lock-regular"></i>
            <strong>SSL</strong> Beveiligde verbinding
          </li>
          <li>
            <i class="frmkt-icon frmkt-icon-user-regular"></i>
            <strong>Half miljoen</strong> mensen geholpen
          </li>
        </ul>
        <div class="slmstrfrms__app-footer-top-contact">
          <strong><i class="frmkt-icon frmkt-icon-phone-regular"></i>088 - 222 1410</strong>
          Werkdagen 8:00 - 17:30
        </div>
      </div>
    </div>
    <div class="slmstrfrms__app-footer-legal">
      <div class="container">
        <div class="slmstrfrms__app-footer-legal-links">
          <a
            href="https://slimster.slim/over/voorwaarden/"
            rel="nofollow nooopener"
            target="_blank"
          >
            Voorwaarden
          </a>
          &amp;
          <a
            href="https://slimster.slim/over/privacy-en-cookiebeleid/"
            target="_blank"
            rel="nofollow nooopener"
          >
            Privacy
          </a>
        </div>
        <div class="slmstrfrms__app-footer-legal-copyright">
          ©2011 - {{ new Date().getFullYear() }} | Slimster B.V.
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/components/AppFooter';
</style>
