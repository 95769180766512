import type { FormKitTypeDefinition } from '@formkit/core'
import { loadSvg } from '../utils/svgLoader'

import {
  outer,
  boxInner,
  help,
  boxHelp,
  messages,
  message,
  prefix,
  suffix,
  fieldset,
  box,
  legend,
  boxOption,
  boxOptions,
  boxWrapper,
  boxLabel,
  options,
  checkboxes,
  $if,
  $extend,
  decorator
} from '@formkit/inputs'

function addSvgLoader(node: any) {
  node.on('created', async () => {
    if (node.props.options) {
      const updatedOptions = [...node.props.options]
      for (let i = 0; i < updatedOptions.length; i++) {
        if (updatedOptions[i].icon) {
          const svgIcon = await loadSvg(updatedOptions[i].icon)
          updatedOptions[i] = {
            ...updatedOptions[i],
            iconSvg: svgIcon
          }
        }
      }
      node.props.options = updatedOptions
    }
  })
}

export const buttonCheckbox: FormKitTypeDefinition = {
  schema: outer(
    fieldset(
      legend('$label'),
      help('$help'),
      $extend(
        boxOptions(
          $extend(
            boxOption(
              boxWrapper(
                boxInner(
                  prefix(),
                  $extend(box(), {
                    bind: '$option.attrs',
                    attrs: {
                      id: '$option.attrs.id',
                      value: '$option.value',
                      checked: '$fns.isChecked($option.value)'
                    }
                  }),
                  $extend(decorator(), {
                    attrs: {
                      innerHTML: '$option.iconSvg'
                    }
                  }),
                  suffix()
                ),
                $extend(boxLabel('$option.label'), {
                  if: '$option.label'
                })
              ),
              boxHelp('$option.help')
            ),
            {
              attrs: {
                class: 'formkit-option formkit-option--button'
              }
            }
          )
        ),
        {
          attrs: {
            class: 'formkit-options formkit-options--buttons'
          }
        }
      )
    ),
    $if('$options == undefined && $help', help('$help')),
    messages(message('$message.value'))
  ),
  type: 'input',
  family: 'box',
  props: ['options', 'onValue', 'offValue', 'optionsLoader'],
  forceTypeProp: 'checkbox',
  features: [options, checkboxes, addSvgLoader],
  schemaMemoKey: 'xk7p4v2n9qj'
}
