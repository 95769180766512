import { defineStore } from 'pinia'
import { ref } from 'vue'

/**
 * Loading Store
 *
 * This store manages the loading state for the application, allowing components to display
 * a loader indicator when certain actions are taking place, such as form submission or data loading.
 */
export const useLoadingStore = defineStore('loading', () => {
  const isLoading = ref(false)
  const message = ref<string | null>(null)

  /**
   * Starts the loading process and sets an optional message.
   *
   * @param {string | null} msg - The message to display during loading.
   */
  function startLoading(msg: string | null = null) {
    isLoading.value = true
    message.value = msg
  }

  /**
   * Stops the loading process and clears the message.
   */
  function stopLoading() {
    isLoading.value = false
    message.value = null
  }

  return { isLoading, message, startLoading, stopLoading }
})
